.print-certificate-wrapper {
  padding: 20px;
  width: 100%;
  h1,
  h2,
  h3,
  h4 {
    margin: 0;
    color: #000;
  }
  th {
    color: #000;
  }
  p {
    margin: 0;
    color: #000;
  }
  td {
    padding-left: 5px;
    color: #000;
  }
  .main-wrapper {
    max-width: 800px;
    margin: 0 auto;
  }
  .element-wrap {
    border: 1px solid #000;
    padding-bottom: 30px;
  }
  .element-header {
    padding: 10px;
    border-bottom: 1px solid #000;
  }
  .element-header h1 {
    font-size: 25px;
    margin: 0;
    padding-bottom: 5px;
    text-align: center;
    font-weight: 600;
  }

  .sub-header {
    padding: 5px;
    border-bottom: 1px solid #000;
  }
  .sub-header h2 {
    font-size: 16px;
    margin: 0;
    text-align: center;
    font-weight: 600;
  }
  .sub-header p {
    text-align: right;
    font-size: 13px;
    padding-right: 20px;
  }
  .overseas-table {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #000;
    font-size: 12px;
  }
  .left-table {
    padding: 5px;
    text-align: left;
  }
  .right-table {
    width: 290px;
  }

  .right-table td {
    text-align: left;
  }

  .span-colon {
    right: 0;
    position: absolute;
    top: 0;
  }
  .tbl-colon {
    position: relative;
    padding-right: 20px;
  }
  .text-center {
    text-align: center !important;
  }

  .text-right {
    text-align: right !important;
  }

  .text-left {
    text-align: left !important;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
  .period-table {
    border-left: 1px solid #000;
    border-bottom: 1px solid #000;
    padding: 5px;
  }

  .period-table-last {
    border-left: 1px solid #000;
    padding: 5px;
  }
  .schedule-header {
    padding: 10px;
  }
  .schedule-header h3 {
    /* text-decoration: underline; */
    font-size: 16px;
    text-align: center;
  }
  .schedule-table table {
    border-spacing: 0;
    font-size: 12px;
  }
  .schedule-table table th,
  .schedule-table table td {
    border: 1px solid #000;
    padding: 4px;
  }

  .pr-50 {
    padding-right: 50px;
  }
  .passport-details table {
    padding: 10px;
  }
  .policy-table {
    border-bottom: 3px solid #000;
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    font-size: 12px;
  }

  .left-table.passport-details th,
  .left-table.passport-details td {
    vertical-align: top !important;
  }

  .schedule-table tr th:first-child,
  .schedule-table tr td:first-child {
    border-left: none;
  }
  .schedule-table tr th:last-child,
  .schedule-table tr td:last-child {
    border-right: none;
  }
  .footer-wrp {
    padding: 7px 0 0;
    font-size: 14px;
  }
  .ft-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
  }
  .ft-btm p {
    text-align: right;
  }
  .vertical-align-top th,
  .vertical-align-top td {
    vertical-align: top;
  }
  .text-danger {
    color: #dc3644;
  }
  .col-w-20 {
    width: 150px !important;
    text-align: left;
  }
}
