@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");
@import "antd/dist/antd.less";
@import "./abstracts/_variable.less";
@import "./abstracts/_lightvariable.less";
// @import "./abstracts/_darkvariable.less";
@import "./abstracts/_media.less";

@import "./base/_base.less";
@import "./base/_typography.less";
@import "./base/_helpers.less";

@import "./component/_button.less";
@import "./component/_modal.less";
@import "./component/_form.less";
@import "./component/_card.less";
@import "./component/_table.less";
@import "./component/_pagination.less";
@import "./component/_breadcrumb.less";
@import "./component/_filter.less";

@import "./layout/_navbar.less";
@import "./layout/_sidebar.less";
@import "./layout/_navdrawer.less";

@import "./pages/_home.less";
@import "./pages/_login.less";
@import "./pages/certificate-print.less";
@import "./pages/_daily.less";
@import "./pages/_register.less";
@import "./pages/_error.less";
@import "./pages/_error-boundary.less";
@import "./pages/_certificate.less";
@import "./pages/_profile.less";
@import "./pages/_token.less";

@import "./vendor/_slick.less";

// @import 'pages/_media.scss';

body {
  font-family: "Roboto", sans-serif;
  background: @bg-color;
}
