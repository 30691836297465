.page__wrapper {
  margin: 70px 0 0 250px;
  transition: all 0.3s ease;
  padding: 30px;
  &.expanded {
    margin-left: 80px;
    @media @lg {
      margin-left: 0;
    }
  }
  @media @lg {
    margin-left: 0;
  }
  @media (max-width: 575px) {
    padding: 20px;
  }
  .card-title {
    font-weight: 400;
  }
}
