.error__page__wrapper{
    .page__inside{
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        max-width: 1200px;
        padding: 0 20px;
        margin: auto;
        img{
            max-width: 600px;
            // @include md{
            //     max-width: 100%;
            //     height: auto;
            // }
        }
        // @include xl{
        //     flex-direction: column-reverse;
        //     text-align: center;
        // }
    }
    h1{
        font-size: 50px;
        font-weight: @bold;
        // @include xl{
        //     font-size: 36px;
        // }
        // @include md{
        //     font-size: 24px;
        //     margin-bottom: 10px;
        // }
    }
    p{
        font-size: 18px;
        // color: @sidebar-toggle-btn-color;
        line-height: 32px;
        line-height: 24px;
        // @include md{
        //     font-size: 14px;
        // }
    }
    .link__goback{
        margin-top: 20px;
    }
}