.ant-card{
    border-radius: 0.635rem;
    margin-bottom: 20px;
    .revenue__amount{
        // @include flex-center(flex, center, flex-start);
        h1{
            margin-right: 1rem;
            font-weight: @bold;
            font-size: 30px;
        }
        span{
            font-size: @font-md;
            font-weight: @bold;
        }
    }
    h3{
        font-size: @font-md;
        font-weight: @medium;
        margin-bottom: 1rem;
    }
    p{
        font-size: @font-14;
        margin-top: .5rem;
    }
    .ant-progress{
        margin-bottom: .5rem;
    }
    .target__texts{
        max-width: 200px;
        margin: auto;
    }
    .ant-table{
        background-color: transparent;
        .ant-table-content{
            th{
                &.ant-table-cell{
                    background-color: transparent;
                    white-space: nowrap;
                    &::before{
                        display: none;
                    }
                }
            }
            td{
                &.ant-table-cell{
                    white-space: nowrap;
                }
            }
        }
        // .ant-avatar{
        //     background-color: @cyan;
        // }
    }
}