.authentication__wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: url('../images/authentication-bg.jpg')no-repeat center/cover;
  text-align: center;
  .authentication__content {
    background-color: @bg-color;
    padding: 20px;
    border-radius: 0.625rem;
    // border: 1px solid #e6ebf1;
    width: 400px;
    > div {
      margin: 1.5rem auto;
      width: 375px;
      .input__groups {
        margin-bottom: 1.5rem;
      }
    }
    .alert__wrapper {
      padding: 15px 0;
      text-align: left;
      .ant-alert-message {
        color: rgb(26, 51, 83);
      }
      .ant-alert-close-icon {
        position: absolute;
        opacity: 0;
        border-radius: 50%;
        height: 15px;
        width: 16px;
        left: 6px;
        overflow: hidden;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .connect__wrapper {
      margin-bottom: 16px;
      span {
        position: relative;
        display: inline-block;
        color: rgba(114, 132, 154, 0.7);
        &::before {
          position: absolute;
          top: 50%;
          left: 110%;
          width: 80px;
          height: 1px;
          background-color: #e6ebf1;
          content: "";
        }
        &::after {
          position: absolute;
          top: 50%;
          right: 110%;
          width: 80px;
          height: 1px;
          background-color: #e6ebf1;
          content: "";
        }
      }
    }
    .ant-input-affix-wrapper {
      border-radius: 0.625rem;
      padding: 8.5px 11px;
      input {
        height: auto;
        border-radius: 0;
        background-color: transparent !important;
      }
    }
    .ant-col {
      &.ant-form-item-label {
        position: relative;
        font-weight: 600;
      }
    }
    .ant-input-prefix {
      .anticon {
        color: rgba(62, 121, 247, 1);
      }
    }
    .ant-form-item-explain-error {
      text-align: left;
      margin-bottom: 10px;
    }
  }
}
