body {
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-font-feature-settings: "liga" 0;
  font-feature-settings: "liga" 0;
  overflow-y: scroll;
  width: 100%;
  position: relative;
}

a,
button,
.btn {
  // @include transition(all .5s ease);
  cursor: pointer;
}

.btn:focus {
  box-shadow: none;
  outline: 0;
}

ul {
  list-style: none;
  padding: 0;
}

.hidden {
  display: none;
}

// h1, h2, h3, h4, h5, h6, p{
//   color: @text-primary;
// }
.text__center {
  text-align: center;
}
.link__goback {
  background-color: #2a59d1;
  border-radius: 0.625rem;
  color: @white !important;
  height: 40px;
  padding: 10px 45px;
  display: inline-flex;
  align-items: center;
  text-align: center;
  > span {
    margin-right: 5px;
  }
  &:hover {
    background-color: #699dff;
    border-color: #699dff;
  }
}
.d__flex {
  display: flex !important;
}
.align__items__center {
  align-items: center !important;
}
.align__items__end {
  align-items: flex-end !important;
}
.align__items__start {
  align-items: flex-start !important;
}
.justify__content__center {
  justify-content: center !important;
}
.justify__content__end {
  justify-content: flex-end !important;
}
.justify__content__start {
  justify-content: flex-start !important;
}

.justify__content__space__beetween {
  justify-content: space-between !important;
}
.filter__select__wrap {
  > div {
    margin-right: 15px;
    h4 {
      margin-bottom: 5px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.text__capitalize {
  text-transform: capitalize;
}
