.ant-input,
.ant-input-password,
.ant-form-item-control-input-content {
  border-radius: 7px;
  height: 42px;
}
.search__wrapper {
  .ant-input {
    min-width: 300px;
  }
}
.ant-form {
  .ant-cascader-picker {
    border-radius: 7px;
  }
  .ant-input-password {
    .ant-input {
      height: auto;
      border-radius: 0;
    }
  }
  .ant-select-selector {
    height: 42px !important;
    border-radius: 7px !important;
    display: flex;
    align-items: center;
    .ant-select-selection-search {
      top: unset;
    }
  }
  .ant-picker {
    height: 42px !important;
    border-radius: 7px;
  }
  .ant-picker-panel-container {
    border-radius: 7px;
    overflow: hidden;
  }
}
// .ant-select-dropdown {
//   border-radius: 0.625rem;
// }
// .ant-input-group-addon {
//   border-radius: 0.625rem;
// }

.ant-picker {
  width: 100%;
}

.ant-input {
  border-radius: 7px;
}
