.ant-layout-sider {
  position: fixed;
  left: 0;
  bottom: 0;
  top: 70px;
  max-width: 250px !important;
  min-width: 250px !important;
  width: 250px !important;
  z-index: 9;
  .ant-menu {
    background-color: transparent;
  }
  .anticon {
    font-size: 16px;
  }
  ul {
    height: 100%;
    .ant-menu-item {
      font-weight: 500;
    }
    .ant-menu-title-content {
      width: 100%;
      transition: all 0.5s ease;
      opacity: 1;
      visibility: visible;
      max-width: 100%;
    }
  }
  .ant-typography {
    padding: 15px 24px 10px;
    display: block;
  }
  &.ant-layout-sider-collapsed {
    max-width: 80px !important;
    min-width: 80px !important;
    width: 80px !important;
    .ant-menu-submenu-arrow {
      display: none;
    }
    .ant-menu-item,
    .ant-menu-submenu-title {
      justify-content: center;
      padding: 0 16px !important;
      text-align: center;
    }
    .ant-menu-title-content,
    .-title-content {
      margin-left: 0;
      overflow: hidden;
      opacity: 0;
      visibility: hidden;
      max-width: 0;
      // display: none;
      width: 100%;
      display: inline-flex;
      // @include lg{
      //     max-width: 100%;
      //     opacity: 1;
      //     visibility: visible;
      //     margin-left: 10px;
      // }
    }
    .ant-typography {
      display: none;
    }
  }
  // @include lg{
  //     display: none;
  // }
}

.ant-menu-submenu-title {
  font-weight: 500;
}
.ant-menu-root {
  .ant-menu-item {
    font-weight: 500 !important;
  }
}

.ant-menu-submenu {
  .ant-menu-item {
    font-weight: 400 !important;
  }
}

.sidenav-container {
  .ant-layout-sider {
    @media @lg {
      display: none;
    }
  }
}

.-title-content {
  width: 100%;
}
