.profile-page-wrapper {
  .profile-detail {
    display: flex;
  }
  .avatar-container {
    height: 120px;
    width: 120px;
    border-radius: 100%;
    margin-right: 30px;
    background: @primary-color;
    .avatar-text {
      font-size: 3rem;
      color: @white;
    }
  }
  table {
    width: 100%;
    td {
      //   border: 1px solid red;
    }
  }
}
