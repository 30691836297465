.header__breadcrumb {
  margin-bottom: 25px;
  .ant-breadcrumb-link {
    text-transform: capitalize;
  }
  .active-link {
    color: @primary-color;
  }
  .ant-breadcrumb > span:last-child a {
    color: @primary-color;
  }
  h1 {
    margin-bottom: 10px;
    margin-right: 20px;
    font-size: 28px;
    line-height: 28px;
  }
}
h4.ant-typography,
.ant-typography h4 {
  margin-bottom: 0px;
}
