.filter-container {
  display: flex;
  .ant-form-item-label > label {
    font-weight: 500;
  }
  .flex-item {
    flex: 1;
    margin-right: 20px;
  }
  .btn {
    align-self: flex-end;
    button {
      height: 100%;
      font-size: 15px;
      border-radius: 6px;
    }
  }
  @media @md {
    flex-direction: column;
    .flex-item {
      margin-right: 0px;
    }
  }
}
