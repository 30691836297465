.navtop {
 margin-bottom: 75px;
}

.formstyle {
 box-shadow: 0px 5px 25px #AAB7F024;
 border-radius: 10px 10px 0px 0px;
 background-color: #FFFFFF;
 max-width: 430px;
 padding-left: 32px;
 padding-right: 32px;
 padding-top: 40px;

}



.imgstyle {

 height: 100%;

}

.h3,
h3 {
 font-size: 22px !important;
}

.g-5,
.gx-5 {
 --bs-gutter-x: 5rem !important;
}

.sub_btn {
 background-color: #252E65;
 height: 40px;
 font: normal normal normal 11px Poppins;
 color: #FFFFFF;
}

.formstyle {
 color: #33373D;
 height: 100%;
}

.contactus {
 min-height: 100%;
}

.formstyle input {
 height: 100%;
}

label {
 font: normal normal medium 12px Inter;
 color: #5A6073;
}

.form-label {
 margin-bottom: .5rem;
 font-size: 12px;
}


.contactno {
 color: #5A6073;
}

.contactno p {
 font-size: 14px;
 color: #3A4065;
}

.h3style {
 font: normal normal medium 16px Inter;
 color: #33373D;
 line-height: 29px;
}

.footerstyle {
 background-color: #4C5275;
 width: 100%;
}

.footerstyle p {
 font-size: 14px;
 color: #FFFFFF;
 font-weight: 300;
 opacity: 70%;
}

.terms,
.contactno_style {
 font-size: 12px;
}

@media (min-width:1240px) {

 .container,
 .container-lg,
 .container-md,
 .container-sm,
 .container-xl {
   max-width: 1194px;
 }

}

@media only screen and (max-width: 992px) {
 .formstyle {
   margin: 0 auto;

 }

 .imgstyle {
   display: block;
   margin-left: auto;
   margin-right: auto;
   width: 70%;
   height: 100%;
   padding-bottom: 10px;

 }

 .navtop {
   margin-bottom: 30px;
 }

 .footerstyle {
   margin-top: 30px;
 }
}

@media only screen and (max-width:578px) {
 .terms {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   margin: 0 auto;
 }

}